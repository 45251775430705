// -----------------------------------
// CARD HOME
// -----------------------------------
.card-container {
	border: 2px solid color(primary-color) !important;
	outline: 1px dashed color(accent-color);
	outline-offset: -1em;
}

.card-title,
.card-subtitle,
.card-formation {
	font-family: $serif !important;
	text-align: center;
}

.card-title {
	margin-top: .5em;
	margin-bottom: 0;
}
.card-subtitle {
	margin-top: .4em;
	color: color(secondary-text-color) !important;
}

// Home round image
//============================= 
.home-round {
	width: 150px;
	height: 150px;
	margin: 2em auto;
	border: 1px solid #eee;
	border-radius: 100%;
	overflow: hidden;
}

.home-round-1 {
	background: url('images/gears.png') no-repeat;
	background-position: 4% 39%;
	background-size: 150% 150%;
}

.home-round-2 {
	background: url('images/therapie-schemas.png') no-repeat;
	background-position: 83% 28%;
	background-size: contain;
}

.home-round-3 {
    background: url('images/souffrance-travail.jpg') no-repeat;
    background-position: 83% 28%;
    background-size: cover;
}

.home-round-4 {
    background: url('images/traumatisme-1.png') no-repeat;
    background-position: 83% 28%;
    background-size: cover;
}

.home-round-5 {
    background: url('images/troubles-anxieux-1.png') no-repeat;
    background-position: 83% 28%;
    background-size: cover;
}

.home-round-6 {
    background: url('images/depression-1.jpg') no-repeat;
    background-position: 83% 28%;
    background-size: cover;
}

// alert
//=============================
.alert {
	position: relative;
	margin: 3em 0;
	padding: .4em .8em;
	background: color-opa(tertiary-color, .2);

	&:before {
		position: absolute;
		top: -26px;
		right: -5px;
		color: color(accent-color);
		font-family: icomoon;
		font-size: 2em;
		content: '\e908';
	}
}

// VIGNETTE
//=============================
@keyframes lunettes {
	0% {
		transform: rotate(40deg);
	}
	50% {
		transform: rotate(45deg);
	}
	100% {
		transform: rotate(40deg);
	}
}

.vignette {
	position: relative;
	padding: .8em;
	border-radius: 6px;
	background: color-lighter(primary-color-light, 8);

	&:before {
		position: absolute;
		top: -20px;
		right: -20px;
		color: color(accent-color);
		font-family: icomoon;
		font-size: 4em;
		content: '\e909';

		animation: 1.5s ease-in-out infinite lunettes;
	}
}

// SIGNATURE
//=============================
.signature {
    text-align: right;
    padding-right: 2em;
    font-family: $sans-serif !important;
    font-size: 3em;
    margin : 0;
}

// SPEECH BUBBLE
//=============================
.speech-bubble {
    margin: 2em 0 2em 1em;
    background : #fff;
    padding: .6em .9em;
    border-radius: 8px;
    position : relative;
    box-shadow: 3px 3px 2px -2px #777;

    &:before {
        content: '';
        display : block;
        position : absolute;
        border-style: solid;
        border-width : 0 15px 15px;
        border-color: #fff transparent;
        width: 0;
        top: -14px;
        right : 22px;
    }
}

// MAP
//=============================
.has-map {
	height: 24em;
}
#map {
	height: 100%;
}

// FAKE HR (Simule saut de bloc)
//=============================
.fake-hr {
    height: 1em;
    @include bleed-x(1em);
    display : block;
    background : url('images/lin-bg.png') repeat;
    border: none;
}
