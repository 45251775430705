.aside-h1 {
    background : color(primary-color-light);
    color: #fff !important;
    text-align : center;
    margin-top : 0;
    line-height : 2;
    @include fz($h2-fz);
}
.o-layout-aside h2 {
    text-align : center;
}
.o-layout-aside p {
    @include pad(2em);
}


