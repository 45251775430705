/* maps to choose from
  ============================= */

$colors-nico : (
  primary-color-dark:   hsla(285, 24%, 40%, 1.00),
  primary-color:        hsla(285, 24%, 50%, 1.00),
  primary-color-light:  hsla(285, 24%, 60%, 1.00),
  primary-color-text:   #FFFFFF,
  accent-color:         hsla(32, 100%, 50%, 1.00),
  primary-text-color:   #212121,
  secondary-text-color: #727272,
  tertiary-color:       hsla(54, 90%, 51%, 1.00),
  divider-color:        #B6B6B6
);

/* opacity map
  ============================= */
$nico-opacity : (
        '.1': .1,
        '.2': .2,
        '.3': .3,
        '.4': .4,
        '.5': .5,
        '.6': .6,
        '.7': .7,
        '.8': .8,
        '.9': .9,
);
/* background: color-nico(color);
  ============================= */
@function color($key: 'cool') {
  @return map-get($colors-nico, $key);
}

// variable qui découle de la fonction
$color-dark-text: color('dark-text');
$color-light-text: color('light-text');
$color-background: color('background');
$color-cool : color('cool');
$color-nav : color('nav');
$color-light : color('light');
$color-button : color('button');

/* color-darker() / color-lighter()
  ============================= */
@function color-darker($color-cool, $level: 0) {
  $color-tint-percentage : 10%;
  $color-to-tint : color($color-cool);
  $color-tint : #000;

  @return mix($color-tint, $color-to-tint, $color-tint-percentage * $level);
}

@function color-lighter($color-cool, $level: 0) {
  $color-tint-percentage : 10%;
  $color-to-tint : color($color-cool);
  $color-tint : #fff;

  @return mix($color-tint, $color-to-tint, $color-tint-percentage * $level);
}

/* color-opa(color, .x);
  ============================= */
@function color-opa($name: 'cool', $opacity: 0) {
  $color : color($name);

  // Get the named opacity level, if it exists
  @if map_has_key($nico-opacity, $opacity) {
    $opacity : map-get($nico-opacity, $opacity);
  }

  // Use rgba() to manipulate the color's alpha level
  @return rgba($color, $opacity);
}

/* =====================================
  end
===================================== */
