#heart {
	width: 20px;

	fill: color(accent-color);
}

.aside-svg-container {
	position: absolute;
	top: -50px;
	right: -50px;
	width: 35px;
	height: 35px;
	z-index: 100;
}

#aside-svg {
	width: 100%;
	height: 100%;

	fill: color(tertiary-color);
}

// TOP SVG CONTAINER
//=============================
.top-anim {
	display: flex;
	margin-bottom: 2em;

	justify-content: center;
}

// ANIM TCC
//=============================
#light-bulb,
#cog,
#question-mark {
	opacity: 0;
}

#light-bulb {
	fill: none;
	stroke-width: 1px;
	stroke: #724e7e;
}

#head {
	fill: #a581b1;
}

// ANIM SOUFFRANCE TRAVAIL
//=============================
.dossier {
	fill: #e53f39;
}

.larme {
	fill: #a581b1;
}

// SCHEMAS
//=============================
.schema-svg svg {
	display: block;
	margin: 3em auto;
}

// SCHEMA ANXIETE
//=============================
.svg-fleche {
	fill: none;
	stroke: #f76054;
	stroke-miterlimit: 10;
}

.pointe-fleche {
    fill:#f76054;
}

#anxiete,
#evitement,
#adaptation {
    font-size:16px;
    fill:#fff;
    font-family: $sans-serif;
}

