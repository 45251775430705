.gallery .gallery__item {
	@include susy-breakpoint($tablet-portrait) {
		@include gallery(6);
	}
}

.gallery__item--home {
    min-height : 30em;
    position : relative;
    padding: 0 !important;
}

.gallery__item--home p {
    @include pad(gutter());
}
