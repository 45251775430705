.site-footer {
	@include susy-breakpoint($tablet-portrait) {
		height: $footer-height;
	}

	clear: both;
	border-top: 4px solid color(accent-color);
	background: color(primary-color-dark);
	overflow: auto;
}

.site-footer h2 {
	color: color(tertiary-color);
}

//
// -----------------------------------
.footer-container {
	@include container();

	position: relative;
	height: 100%;
	padding: gutter();
}

.footer-container h4,
.footer-container p,
.footer-container span {
	color: color-lighter(primary-color, 4);
}

.footer-container h2 {

	font-weight: 100;

    @include susy-breakpoint($tablet-portrait) {
        margin-top : 0;
    }
}

.footer-sitemap,
.footer-schema,
.footer-presentation {
	min-height: 100%;
}

.footer-schema,
.footer-sitemap {
	@include susy-breakpoint($tablet-portrait) {
		@include span(4);

		border-right: 1px dashed color-lighter(tertiary-color, 7);
	}
}

.footer-presentation {
	@include susy-breakpoint($tablet-portrait) {
		@include span(4 last);
	}
}

span[itemprop='postalCode'] {
	padding-right: 4px;
	float: left;
}

span[itemprop='telephone'] {
	font-size: 1.2em;

	&:before {
		padding-right: .5em;
		color: color(primary-color-dark);
		font-family: icomoon;
		content: '\e903';
	}
}

span[itemprop] {
	display: block;
	padding-bottom: 4px;
}


.adeli-siret,
span[itemprop='telephone'] {
	width: 60%;
	margin: 1em auto 0;
	padding: .5em;
	border-radius: 10px;
	background: color(tertiary-color);
	color: #000 !important;
	text-align: center;
	box-shadow: 4px 4px 6px -2px #45304c;
}

// NICODEV
//-------------------------
.nicodev {
	float: right;
	color: color(tertiary-color);
	font-family: $sans-serif;
	letter-spacing: 1px;
}

.nicodev a {
	color: inherit;
	font-family: inherit;
}
