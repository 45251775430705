.icon--primary:before {
	padding-right: .5em;
	color: color(primary-color-light);
	font-size: 1.2em;
}

.icon-question:before {
	color: color-lighter(accent-color, 5);
}

.icon-phone {
	position: relative;
	&:before {
		position: absolute;
		top: 2px;
		left: -44px;
	}
}
