th ,
td ,
caption {
    height: 50px;
}

caption ,
td {
    font-family: $serif;
}
.table-tarifs {
    min-width: 200px;
    max-width: 300px;
    margin: 0 auto;
    border: 5px solid color(primary-color-dark);
    background: #eee;
    caption {
        background: color(primary-color-dark);
        color: #fff;
        font-weight: 700;
        line-height: 50px;
    }
    th {
        background: color(primary-color);
        color: #fff;
        font-family: $sans-serif;
    }
    td {
        background: color(accent-color);
        color: #fff;
        font-family: $sans-serif;
        font-size: 1.7em;
        text-align: center;
    }
}

.table-professions {
    margin: 3em auto;
    
    @include susy-breakpoint($tablet-portrait) {
    min-width: 500px;
        
    }

    caption {
        background: color(primary-color-light);
        line-height: 50px;
        font-size: 1.1em;
        font-weight: 700;
    }

    th {
        background: color(primary-color-dark);
        color: #eee;
        font-weight: 400;
        border-right: 1px solid #fff;
    }
    td {
        text-align: center;
        background: color-lighter(primary-color, 9);
        border: 1px solid #fff;
        font-size: 1.1em;
    }
}




