// Listes
// -----------------------------------

.liste li {
	position: relative;
	padding-bottom: .5em;
	padding-left: 10px;
	font-family: $serif;
	line-height: 1.4em;

	list-style-type: none;

	&:before {
		position: absolute;
		top: -3px;
		left: -10px;
		color: color(accent-color);
		font-family: icomoon;
		content: '\e901';
	}
}

.card-adress {
	position: relative;
	width: 90%;
	margin: 2em auto;
	padding: .5em .5em .5em 2em;
	background: hsla(0, 0%, 98%, .4);
    border: 1px solid #c0c0c0;

	&:before {
		position: absolute;
		right: -7px;
        top :-7px;
		font-family: icomoon;
		content: '\e90a';
        color: color(accent-color);
        font-size : 2em;
        transform: rotate(10deg);
        z-index :100;
	}
}

.card-adress li {
	@include lh($p-lh);

	position: relative;
	margin-bottom: .5em;
	font-family: $serif;

	list-style-type: none;

	&:before {
		position: absolute;
		left: -1.5em;
		padding-right: .4em;
		color: color(primary-color);
		font-family: icomoon;
		content: '\e905';
	}
}
