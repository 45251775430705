@font-face {
    font-family: 'icomoon';
    src:    url('fonts/icomoon.eot?6zlsck');
    src:    url('fonts/icomoon.eot?6zlsck#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?6zlsck') format('woff2'),
    url('fonts/icomoon.ttf?6zlsck') format('truetype'),
    url('fonts/icomoon.woff?6zlsck') format('woff'),
    url('fonts/icomoon.svg?6zlsck#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-location:before {
    content: "\e90a";
}
.icon-glasses:before {
    content: "\e909";
}
.icon-bubble-notification:before {
    content: "\e908";
}
.icon-question:before {
    content: "\e906";
}
.icon-arrow-right:before {
    content: "\e905";
}
.icon-home:before {
    content: "\e904";
}
.icon-furniture:before {
    content: "\e902";
}
.icon-phone:before {
    content: "\e903";
}
.icon-information:before {
    content: "\e907";
}
.icon-wheelchair:before {
    content: "\e900";
}
.icon-angle-double-right:before {
    content: "\e901";
}

