$sans-serif: 'tamarillo-jf', sans-serif;
$serif: 'ff-meta-serif-web-pro', serif;

// -----------------------------------
// FONT-SIZE MAP
// -----------------------------------
$h1-fz: (
        null : 1em,
        320px : 1.5em,
        375px : 2em,
        768px : 2.5em,
        1200px : 3em
);
$h2-fz: (
        null : 1.625em,
        320px : 1.625em,
        375px : 1.5em,
        768px : 2em,
        1200px : 2em
);
$h3-fz: (
        null : 1.625em,
        320px : 1.625em,
        375px : 1.375em,
        768px : 1.75em,
        1200px : 1.75em
);
$h4-fz: (
        null : 1.625em
);
$h5-fz: (
        null : 1em
);

@mixin fz($fs-map) {
	@each $fs-breakpoint, $fs-font-size in $fs-map {
		@if $fs-breakpoint == null {
			font-size: $fs-font-size;
		} @else {
			@media screen and(min-width: $fs-breakpoint) {
				font-size: $fs-font-size;
			}
		}
	}
}

// -----------------------------------
// LINE-HEIGHT MAP
// -----------------------------------
$p-lh: (
        null : 1.25em,
        320px : 1.25em,
        375px : 1.125em,
        768px : 1.375em
);
$h1-lh: (
        null : 1.25em,
        1200px : 1.50em
);
$h2-lh: (
        null : 1.15384615em,
        320px : 1.15384615em,
        375px : 1.25em
);
$h3-lh: (
        null : 1.13636364em,
        320px : 1.13636364em,
        375px : 1.25em
);
$h4-lh: (
        null : 1.11111111em,
        320px : 1.11111111em,
        375px : 1.22222222em
);

@mixin lh($lh-map) {
	@each $lh-breakpoint, $lh in $lh-map {
		@if $lh-breakpoint == null {
			line-height: $lh;
		} @else {
			@media screen and(min-width: $lh-breakpoint) {
				line-height: $lh;
			}
		}
	}
}

// -----------------------------------
// HEADERS
// -----------------------------------
h1 {
	@include fz($h1-fz);
	@include lh($h1-lh);
}

.main-header {
	margin-top: 0;
	padding-left: .4em;
	box-shadow: -4px 0 0 color(accent-color),
	-10px 0 0 color(tertiary-color);
}

h2 {
	@include fz($h2-fz);
	@include lh($h2-lh);
}

h3 {
	@include fz($h3-fz);
	@include lh($h3-lh);
}

h4 {
	@include fz($h4-fz);
	@include lh($h4-lh);
}

h5,
h6 {
	@include fz($h5-fz);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
	transition: opacity .3s ease-in;
}

.wf-loading h1,
.wf-loading h2,
.wf-loading h3,
.wf-loading h4,
.wf-loading h5,
.wf-loading h6,
.wf-loading .site-nav__li a {
	opacity: 0;
}

.wf-active h1,
.wf-active h2,
.wf-active h3,
.wf-active h4,
.wf-active h5,
.wf-active h6 {
	color: color(primary-text-color);
	font-family: $sans-serif;
	font-weight: 400;
	opacity: 1;
}

.wf-loading p {
	font-family: serif;
	opacity: .4;
}

.wf-active p {
	@include lh($p-lh);

	font-family: $serif;
	font-weight: 500;
	opacity: 1;
}

.text-link,
.liste li a,
p a  {
	background: linear-gradient(color(accent-color), color(accent-color)) no-repeat;
	background-position: 0 .9em;
	background-size: 100% 1px;
	color: color(primary-color-dark);
	text-decoration: none;
	text-shadow: .05em 0 white, -.05em 0 white;
}

.subtitle {
	color: color(secondary-text-color);
}
