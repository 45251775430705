span[itemprop='name'] {
    padding-bottom: 1em;
    color: color(accent-color);
}

span[itemprop] {
    font-family: $serif;
}

span[itemprop='name'] ,
span[itemprop='address'] ,
span[itemprop='streetAddress'] ,
span[itemprop='telephone'] {
    display: block;
}

span[itemprop='postalCode'] {
    padding-right: 10px;
}

span[itemprop='telephone'] {
    padding-top: 1em;
}
