body {
    background: #fff url('images/lin-bg.png') repeat;
}
.o-layout-container {
    @include container();

    min-height: calc(100vh - 25em); // sticky footer header + footer height
    padding: gutter();
    clear: left;
    background: #fff url('images/lin-bg.png') repeat;
}

.o-layout-main {
    @include susy-breakpoint($tablet-portrait) {
        @include span(9);
    }
}

.o-layout-aside {
    @include susy-breakpoint($tablet-portrait) {
        @include span(3 last);

        position: relative;
        background: linear-gradient(to bottom, #f8f8f2 85%, transparent 100%);
        overflow: hidden;
        padding-bottom : 4em;
    }
}

.o-layout-block {
    margin-bottom: gutter();
    padding: gutter();
    border-radius: 5px;
    background: #fff;
}

.o-layout-block--half-left {
    @include susy-breakpoint($tablet-paysage) {
        @include span(7 no-gutter);
    }
}

.o-layout-block--half-last {
    @include susy-breakpoint($tablet-paysage) {
        @include span(5 no-gutter last);
    }
}

.o-layout-block--half-left h2,
.o-layout-block--half-last h2 {
    margin-top: 0;
    background: color(primary-color-light);
    color: #fff;
    font-size: 1.7em;
    line-height: 2;
    text-align: center;
}

.gallery__item h2 {
    @include susy-breakpoint($tablet-paysage) {
        font-size: 2.5em;
    }
    @include susy-breakpoint($desktop) {
        font-size: 2.7em;
    }

    margin: 0;
    border-bottom: 3px solid color(tertiary-color);
    background: color(primary-color-light);
    color: #fff !important;
    font-size: 1.7em;
    line-height: 2;
    text-align: center;
}

.home-left,
.card-container {
    height: 30em;
}
