// HEADER
//=============================
.site-header {
	position: relative;
	height: 165px;
	background: color(primary-color-dark) url('images/header-bg.png') repeat;
}

.site-header__container {
	@include container();

	position: relative;
	height: 165px;
}

.header-infos-container {
	@include full();

	height: 3em;
	border-top: 3px solid color(tertiary-color);
	background: color-darker(primary-color-dark,4);
}

.header-infos-inner {
	@include container();

	position: relative;
	height: 100%;
}

.phone-top {
	display: inline-block;
	position: absolute;
	left: 5%;
	margin: 0;
	color: color(tertiary-color);
	font-size: 1.7em;
	line-height: 1.7 !important;
}

.site-header__logo {

    @include susy-breakpoint($tablet-portrait) {

        position : relative;

        margin : 0;
    }

	@include susy-breakpoint($tablet-paysage) {

	}

	position: absolute;
    margin : auto;
    right : 0;
    left : 0;
	padding: .5em 1em;

	background: linear-gradient(to right, transparent, color-lighter(primary-color-dark, 2), transparent);
	z-index: 1;
    text-align : center;
}

.site-header__logo p {
	margin: 0;
	font-family: $sans-serif;

	&:first-of-type,
	&:last-of-type {
		display: inline;
	}

	&:first-of-type {
		@include susy-breakpoint($tablet-portrait) {
			font-size: 2em;
		}
		@include susy-breakpoint($tablet-paysage) {
			font-size: 3em;
		}

		color: #fff;
		font-size: 1em;
	}
	&:last-of-type {
		@include susy-breakpoint($tablet-portrait) {
			font-size: 1.8em;
		}
		@include susy-breakpoint($tablet-paysage) {
			font-size: 2.5em;
		}

		color: color-darker(primary-color-text, 1);
		font-size: 1em;
        padding-left : 1em;
	}
}

.header-info {
	color: color-opa(primary-color-light, .9);
}
