$nav-border-color: 3px solid color(tertiary-color);
.site-nav {
	@include susy-breakpoint($tablet-portrait) {
		bottom: -3px;
		width: 45em;
		visibility: visible;
	}
	@include susy-breakpoint($tablet-paysage) {
		width: 54em;
	}
	@include susy-breakpoint($desktop) {
		width: 100%;
	}

	position: absolute;
	right: 0;
	bottom: -13em;
	left: 0;
	visibility: hidden;
	z-index: 100;
}

.site-nav__ul {
	@include susy-breakpoint($tablet-portrait) {
		float: right;
	}

	margin-bottom: 0;
	padding-left: 0;
}

.site-nav__li {
	@include susy-breakpoint($tablet-portrait) {
		display: inline-block;
		border: $nav-border-color;
		border-bottom: none;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
	}

	margin: 0 .2em 0 0;
	padding: 0;
	transition: background .2s;
	background: color(primary-color-dark);
	line-height: 50px;
	&:hover {
		background: color(accent-color);
	}
}

.site-nav__li,
.footer-nav ul li {
	list-style-type: none;
}

.site-nav ul li a,
.footer-nav ul li a {
	text-decoration: none;
}

.site-nav__li a {
	@include susy-breakpoint($tablet-portrait) {
		font-size: 1.2em;
		text-align: left;
	}
	@include susy-breakpoint($tablet-paysage) {
		font-size: 1.5em;
	}
	@include susy-breakpoint($desktop) {
		font-size: 1.7em;
	}

	display: block;
	padding: 0 .6em;
	color: #fff;
	font-family: $sans-serif;
	font-size: 1.3em;
	text-align: center;
}

// DROPDOWN
.has-dropdown {
	position: relative;
	&:hover > .dropdown {
		height: auto;
		visibility: visible;
	}
}

.dropdown {
	position: absolute;
	top: 50px;
	left: 0;
	min-width: 18em;
	height: 0;
	padding-left: 0;
	background: color-darker(primary-color-dark, 2);
	box-shadow: 7px 8px 14px -8px black;
	visibility: hidden;
	z-index: 100;
}

.dropdown__li {
	display: block;
	border-bottom: 1px solid #eee;
}

.dropdown__li a:hover {
	background: color(accent-color);
}

// BURGER
//=============================
.burger-menu {
	@include susy-breakpoint($tablet-portrait) {
		display: none;
	}

	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 80px;
	height: 80px;
	margin: auto;
	cursor: pointer;
	z-index: 100;
}

.burger {
	position: absolute;
	top: 50%;
	right: 0;
	margin-top: -5px;
	background: color(tertiary-color);
	opacity: 1;
}

.burger:before,
.burger:after {
	display: block;
	position: absolute;
	background: color(tertiary-color);
	content: '';
}

.burger::before {
	top: 20px;
}
.burger::after {
	bottom: 20px;
}

.burger::after,
.burger::before,
.burger {
	width: 100%;
	height: 10px;
	transition: all .3s ease-in-out;
}

.burger-menu--on + .site-nav {
	visibility: visible;
}


// FOOTER NAV
//=============================

.footer-nav__ul {
	padding-left: 0;
}

.footer-nav .dropdown__li {
	border-bottom: 0;
}

.footer-nav li {
	display: block;
	line-height: 2;
	max-width: 80%;
}

.footer-nav__li .nav-active {
	background: none;
}

.footer-nav ul li a {
	display: block;
	color: color-lighter(primary-color-light, 3);
	font-size: 1em;
}

.nav-active {
	background: color-opa(primary-color, .6);
	cursor: not-allowed;
}
