.has-curled-shadow {
	position: relative;
	padding: 1em 2em;
	border: 1px solid #eee;
	background: color-lighter(primary-color-light, 7);
	font-size: 1.1em;
	&:before,
	&:after {
		position: absolute;
		top: 80%;
		bottom: 15px;
		left: 10px;
		width: 50%;
		max-width: 300px;
		transform: rotate(-3deg);
		background: #777;
		box-shadow: 0 15px 10px #777;
		content: '';
		z-index: -1;
	}
	&:after {
		right: 10px;
		left: auto;
		transform: rotate(3deg);
	}
}

.btn-container {
	display: flex;
	margin-right: 1em;

	flex-direction: column;
}

.btn {
	display: inline-block;
	position: relative;
	margin-bottom: 1em;
    padding: .4em .6em;
	transition: all .3s;
	border-radius: 4px;
	background: color(accent-color);
	color: #fff;
	font-family: $sans-serif;

	font-weight: 700;
	text-decoration: none;
	box-shadow: 4px 4px 4px -4px #777;

    @include susy-breakpoint($tablet-portrait) {
        font-size: 1.5em;
        padding: .8em 1.2em;
    }

	&:hover {
		background: color-darker(accent-color, 2);
	}
}

.btn--link {
	min-width: 10em;
	padding: .5em 1.8em .6em .8em;
    margin-right: .5em;
	text-align: center;

    @include susy-breakpoint($tablet-portrait) {
        margin-right : 1em;
    }
	&:after {
		position: absolute;
		top: .65em;
		right: .7em;
		color: #fff;
		font-family: icomoon;
		font-size: 1em;
		font-weight: 500;
		content: '\e901';
	}
}

.btn--left {
	align-self: flex-start;
}

.btn--center {
	align-self: center;
}

.btn--right {
	position: absolute;
	right: 0;
	bottom: 0;
}

.hr {
	display: block;
	height: 1px;
	margin: 2em 0;
	border: none;
	background: linear-gradient(to right, #fff, color(primary-color), #fff);
}

// -----------------------------------
// TOOLTIPS
// -----------------------------------
